import axios from 'axios'
import Vue from 'vue'


/**
 * @category Headers ::
 * ::::::::::::::::::::::::::::::::::::::::::::::::::::
 */
var config = {headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}};


Vue.prototype.$http = axios;

/**
 * @category Route ::
 * ::::::::::::::::::::::::::::::::::::::::::::::::::::
 */
  // const baseUri = 'http://apiquebrantable.test/api/';
    const baseUri = 'https://api.convencioncomercial.com.mx/api/';

var api = {

  baseUri: baseUri,

    // baseUriFiles: "http://apiquebrantable.test/storage/",
    baseUriFiles: "https://api.convencioncomercial.com.mx/uploads/",



  get: function (endpoint, params = null, show_lodader = false) {

    var url = new URL(baseUri + endpoint);

    if (params) {
      Object.keys(params).forEach(function (key) {
        url.searchParams.append(key, params[key]);
      });
    }

    show_lodader ? api.loader(true) : null;

    return new Promise((resolve) => {
      axios.get(url.href, config).then(
        response => {
          show_lodader ? api.loader(false) : null;
          resolve(response)
        }
      )
    });


  },
  post: function (endpoint, data, show_lodader = false) {

    show_lodader ? api.loader(true) : null;

    return new Promise((resolve) => {
      axios.post(baseUri + endpoint, data, config).then(
        response => {

          show_lodader ? api.loader(false) : null;
          resolve(response)
        }
      )
    });


  },
  authorization: function (status, token) {
    if (status) {
      return axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    } else {
      return delete axios.defaults.headers.common['Authorization']
    }
  }
};

export default api;
